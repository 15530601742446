
// import * as echarts from "echarts";
import _ from 'lodash';

export const LinePath = 'path://M0 0 C3.3 0 6.6 0 10 0 C10 0.66 10 1.32 10 2 C6.7 2 3.4 2 0 2 C0 1.34 0 0.68 0 0 Z'

export const baseConfig = {
    tooltip: {
        trigger: 'axis',
    },
    grid: {
        left: 30,
        right: 30,
        bottom: 50,
        top: 50,
    },
    legend: {
        icon: 'rect',
        itemGap: 16,
        height: 20,
        bottom: 0,
        itemWidth: 10,
        itemHeight: 8,
        textStyle: {
            color: 'rgba(51, 51, 51, 0.9)',
            fontSize: 12,
            lineHeight: 20
        },
    },
    xAxis: {
        type: 'category',
        data: [],
        axisTick: {
            show: false
        },
        axisLabel: {
            textStyle: {
                color: '#8F95B2'
            }
        },
        axisLine: {
            lineStyle: {
                color: "#D8DAE5",
            }
        },
    },
    yAxis: [
        {
            type: 'value',
            name: '条',
            alignTicks: true,
            nameTextStyle: {
                color: '#8F95B2'
            },
            axisTick:{

            },
            splitLine: {
                lineStyle: {
                    type: 'dashed',
                }
            },
            axisLabel: {
                textStyle: {
                    color: '#8F95B2'
                }
            }
        },
        {
            type: 'value',
            name: '',
            alignTicks: true,
            splitLine: {
                lineStyle: {
                    type: 'dashed',
                }
            },
            nameTextStyle: {
                color: '#8F95B2'
            },
            axisLabel: {
                textStyle: {
                    color: '#8F95B2'
                }
            }
        }
    ],
}

export function mixBaseConfig() {
    let option = _.cloneDeep(baseConfig)
    option.color = ['#BBCAE8','#2389FF', '#00B578'] 
    option.legend.data = [{ name: '上行' }, { name: '下行' }]
    option.grid.left = 70
    option.series = [
        {
            name: '上行',
            type:"bar",
            data: [],
        },
        {
            name: '下行',
            type:"bar",
            data: [],
        },
    ]
    return option
}
export function mixBaseConfig2() {
    let option = _.cloneDeep(baseConfig)
    option.color = ['#2389FF', '#E8B202'] 
    option.grid = {
        left: 20,
        right: 60,
        bottom: 24,
        top: 50,
    };
    option.xAxis = [
        {
            type: 'value',
            name:'条',
            axisTick: {
                show: false
            },
            axisLabel: {
                textStyle: {
                    color: '#8F95B2'
                }
            },
            axPointer:{
                show:false
            },
            splitLine: {
                lineStyle: {
                    type: 'dashed',
                }
            },
            axisLine: {
                show:false,
                lineStyle: {
                    color: "#D8DAE5",
                }
            },
        },
        {
            type: 'value',
            alignTicks:true,
            name:'',
            axisTick: {
                show: false
            },
          
            splitLine: {
                show:false
            },
            axisLabel: {
                show:false,
                textStyle: {
                    color: '#8F95B2'
                }
            },
            axisLine: {
                show:false,
                lineStyle: {
                    color: "#D8DAE5",
                }
            },
        }
    ]
    option.yAxis[0].axisTick.show = false 
    option.yAxis[0].name = ''
    option.yAxis[0].type = 'category'
    option.yAxis[0].triggerEvent = true
    option.yAxis[0].axisLabel = {
        inside: true,
        verticalAlign: 'bottom',
        lineHeight: 34,
        color:'#86909C'
    }
    option.yAxis[0].axisLine = {
        lineStyle:{
            color:'#e6e8eb'
        }
    }
    option.xAxis[0].axisPointer = {
        show:false
    }
    option.xAxis[1].axisPointer = {
        show:false
    }
    option.yAxis[1].show = false
    option.legend.show = false
    option.dataZoom = [
        {
            show: true,
            yAxisIndex: 0,
            filterMode: 'empty',
            width: 10,
            height: '94%',
            backgroundColor:'#fff',
            fillerColor:'#CCCCCC',
            start: 0,
            end: 100,
            zoomLock:true,
            brushSelect: false,
            showDetail:false,
            showDataShadow: false,
            right: '0%'
        }
    ];
    option.series = [
        {
            name: '设备消息量',
            type: 'bar',
            data: [],
            barMaxWidth: '12',
            tooltip:{
                valueFormatter:function(value){
                    return value + ' 条'
                }
            }
        },
    ]
    return option
}
export function mixBaseConfig3() {
    let option = _.cloneDeep(baseConfig)
    option.color = ['#2389FF', '#E8B202'] 
    option.yAxis[0].name = ''
    option.xAxis.axisLabel.rotate = 35
    option.xAxis.axisLabel.formatter = function(value){
        if(value.length>4){
            return `${value.slice(0,4)}...`
        }
        return value
    }
    option.yAxis[1].show = false
    option.legend.show = false
    option.series = [
        {
            name: '报修数量',
            type: 'bar',
            data: [],
            barMaxWidth: '20',
            tooltip:{
                valueFormatter:function(value){
                    return value + '个'
                }
            }
        },
    ]
    return option
}
