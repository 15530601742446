<template>
  <div class="monitor-main">
    <div class="top-info">
      <section>
        <h3>最近5分钟消息数</h3>
        <div>{{ viewInfo.last5MinuteCount || "--" }}<i> 条</i></div>
      </section>
      <section>
        <h3>当日消息数量</h3>
        <div>{{ viewInfo.currentDayCount || "--" }}<i> 条</i></div>
      </section>
      <section>
        <h3>当月消息数量</h3>
        <div>{{ viewInfo.currentMonthCount || "--" }}<i> 条</i></div>
      </section>
      <section>
        <h3>当年消息数量</h3>
        <div>{{ viewInfo.currentYearCount || "--" }}<i> 条</i></div>
      </section>
    </div>
    <div class="content-info">
      <div class="left-block">
        <div class="top-block">
          <section class="header">
            <span>消息总量</span>
            <a-date-picker
              v-model:value="daySelect"
              size="small"
              format="YYYY年MM月DD日"
              valueFormat="YYYY-MM-DD"
              placeholder="选择年"
              @change="getMessageByDay"
            />
          </section>
          <div class="carbon">
            <BaseChart
              v-if="mixOption1"
              width="100%"
              height="84%"
              :options="mixOption1"
            >
            </BaseChart>
          </div>
        </div>
        <div class="bottom-block">
          <section class="header">
            <span>消息总量</span>
            <a-date-picker
              v-model:value="monthSelect"
              size="small"
              picker="month"
              format="YYYY年MM月"
              valueFormat="YYYY-MM"
              placeholder="选择年"
              @change="getMessageByMonth"
              :allowClear="false"
            />
          </section>
          <div class="carbon">
            <BaseChart
              v-if="mixOption2"
              width="100%"
              height="84%"
              :options="mixOption2"
            >
            </BaseChart>
          </div>
        </div>
      </div>
      <div class="right-block">
        <section class="header">
          <span>设备消息量排序</span>
          <a-date-picker
            v-model:value="daySelect2"
            size="small"
            format="YYYY年MM月DD日"
            valueFormat="YYYY-MM-DD"
            placeholder="选择年"
            @change="getMessageList"
            :allowClear="false"
          />
        </section>
        <div class="carbon">
          <BaseChart
            v-if="mixOption3"
            width="100%"
            height="90%"
            :options="mixOption3"
          >
          </BaseChart>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import { ref, watch, nextTick, onMounted } from "vue";
import { apiGateWay } from "@/api/gateway.js";
import { mixBaseConfig, mixBaseConfig2 } from "./config";
import { useRoute } from "vue-router";
import dayjs from "dayjs";

const route = useRoute();
const daySelect = ref(dayjs().format("YYYY-MM-DD"));
const daySelect2 = ref(dayjs().format("YYYY-MM-DD"));
const monthSelect = ref(dayjs().format("YYYY-MM"));

watch(
  () => route.fullPath,
  () => {
    if (route.fullPath.includes("/IoTManage/gateway/detail")) {
      nextTick(() => {
        getMessageByDay();
        getMessageByMonth();
        getMessageList();
        getView();
      });
    }
  }
);

onMounted(() => {
  getMessageByDay();
  getMessageByMonth();
  getMessageList();
  getView();
});

const viewInfo = ref({});
const getView = () => {
  apiGateWay.monitorView(route.params.bizId).then(({ result }) => {
    viewInfo.value = result;
  });
};

const mixOption1 = ref(mixBaseConfig());
const getMessageByDay = () => {
  const [year, month, day] = daySelect.value.split("-");
  let param = {
    year,
    month,
    day,
  };
  apiGateWay.messageDay(route.params.bizId, param).then(({ result }) => {
    mixOption1.value.xAxis.data = result[0].xlist || [];
    mixOption1.value.series[0].data = result[0].ylist || [];
    mixOption1.value.series[1].data = result[1].ylist || [];
    console.log(123, mixOption1);
  });
};

const mixOption2 = ref(mixBaseConfig());
const getMessageByMonth = () => {
  const [year, month] = monthSelect.value.split("-");
  let param = {
    year,
    month,
  };
  apiGateWay.messageMonth(route.params.bizId, param).then(({ result }) => {
    mixOption2.value.xAxis.data = result[0].xlist || [];
    mixOption2.value.series[0].data = result[0].ylist || [];
    mixOption2.value.series[1].data = result[1].ylist || [];
  });
};

const mixOption3 = ref(mixBaseConfig2());
const getMessageList = () => {
  const [year, month, day] = daySelect2.value.split("-");
  let param = {
    year,
    month,
    day,
  };
  apiGateWay.messageList(route.params.bizId, param).then(({ result }) => {
    let names = result.map((item) => item.deviceName);
    let values = result.map((item) => item.msgCount);
    console.log(123, names, values);
    mixOption3.value.yAxis[0].data = names || [];
    mixOption3.value.series[0].data = values || [];
    mixOption3.value.dataZoom[0].show = names.length <= 7 ? false : true;
    mixOption3.value.dataZoom[0].start =
      100 - (names.length > 7 ? (7 / names.length) * 100 : 100);
  });
};
</script>
<style lang="less" scoped>
.carbon {
  height: 100%;
}
.top-info {
  display: flex;
  justify-content: space-between;
  & > section {
    width: 24%;
    height: 140px;
    border: 1px solid #e6e8eb;
    border-radius: 4px;
    h3 {
      font-size: 14px;
      margin: 20px 0 15px 20px;
    }
    div {
      text-align: center;
      font-size: 30px;
      font-weight: bold;
      i {
        font-style: normal;
        font-size: 14px;
        font-weight: normal;
      }
    }
  }
}
.content-info {
  display: flex;
  height: calc(100vh - 350px);
  justify-content: space-between;
  margin-top: 20px;
  .header {
    display: flex;
    padding-left: 20px;
    height: 40px;
    align-items: center;
    border-bottom: 1px solid #e6e8eb;
    span {
      margin-right: 10px;
    }
  }
  .left-block {
    display: flex;
    width: 49.3%;
    flex-direction: column;
    justify-content: space-between;
    .top-block,
    .bottom-block {
      height: 48%;
      border: 1px solid #e6e8eb;
      border-radius: 4px;
    }
  }
  .right-block {
    width: 49.3%;
    height: 100%;
    border: 1px solid #e6e8eb;
    border-radius: 4px;
  }
}
</style>
