<template>
  <div>
    <div class="body">
      <div class="left">
        <div class="left_action">
          <span>监听：</span>
          <a-input v-model:value="listenTopic" />
          <a-button type="primary" v-if="!startListenFlag" @click="startListen"
            >开始</a-button
          >
          <a-button type="primary" v-else @click="stopListen">停止</a-button>
        </div>
        <div class="content">
          <ul>
            <li v-for="(item, index) in listenInfo" :key="index">
              <p>{{ item.time }}</p>
              <div>
                {{ item.message }}
              </div>
            </li>
          </ul>
        </div>
      </div>
      <div class="right">
        <section class="right_action">
          <span>人工发送：</span>
          <a-input v-model:value="sendTopic" />
        </section>
        <div class="example">
          <ul>
            <li v-for="(item, index) in sendInfo" :key="index">
              <p>{{ item.time }}</p>
              <div>
                {{ item.message }}
              </div>
            </li>
          </ul>
          <section>
            <a-textarea v-model:value="sendMsg" />
            <a-button type="primary" @click="startSend">发送</a-button>
          </section>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import { onMounted, ref } from "vue";
// import { apiGateWay } from "@/api/gateway.js";
// import { useRoute } from "vue-router";
import { message } from "ant-design-vue";
import useMqtt from "@/composition/useMqtt.js";
import dayjs from "dayjs";

const { startMqtt, publishMqtt } = useMqtt();

const listenTopic = ref("");
const listenInfo = ref([]);
const listenMqttInstance = ref(null);
const startListenFlag = ref(false);
const startListen = () => {
  startListenFlag.value = true;
  if (!listenTopic.value) {
    message.warning("请填写监听：topic");
    return;
  }
  if (listenTopic.value) {
    listenInfo.value = [];
    if (listenMqttInstance.value) {
      listenMqttInstance.value.unsubscribe();
      listenMqttInstance.value.over();
    }
    listenMqttInstance.value = startMqtt(
      listenTopic.value,
      (topic, message) => {
        listenInfo.value.unshift({
          time: dayjs(new Date()).format("YYYY-MM-DD HH:mm:ss"),
          message,
        });
      },
      (err) => {
        if (err) {
          message.error("订阅失败");
        } else {
          message.success("订阅成功，开始监听");
        }
      }
    );
  }
};

const stopListen = () => {
  startListenFlag.value = false;
  listenMqttInstance.value.unsubscribe();
  listenMqttInstance.value.over();
};

const sendMsg = ref(null);
const sendTopic = ref("");
const sendInstance = ref(null);
const sendInfo = ref([]);
const currentSendTopic = ref("");
const startSend = () => {
  if (currentSendTopic.value != sendTopic.value) {
    sendInfo.value = [];
  }
  if (!sendTopic.value) {
    message.warning("请填写人工发送：topic");
    return;
  }
  sendInstance.value = publishMqtt(
    sendTopic.value,
    sendMsg.value,
    (topic, message) => {
      sendInfo.value.unshift({
        time: dayjs(new Date()).format("YYYY-MM-DD HH:mm:ss"),
        message,
      });
    },
    (err) => {
      currentSendTopic.value = sendTopic.value;
      console.log(3333, err)
      // if (err) {
      //   message.error("订阅失败");
      // } else {
      //   message.success("订阅成功，开始监听");
      // }
    }
  );
};

onMounted(() => {});
</script>
<style lang="less" scoped>
.left_action {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  span {
    width: 45px;
  }
  .ant-select {
    width: 80px;
    margin: 0 10px;
  }
  .ant-input {
    width: 300px;
    margin-right: 10px;
  }
}
.right_action {
  display: flex;
  align-items: center;
  flex: 1;
  margin-bottom: 20px;
  .ant-select {
    width: 80px;
    margin: 0 10px;
  }
  .ant-input {
    width: 230px;
  }
}
.body {
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
  .left {
    flex: 1;
    margin-right: 20px;
  }
  .right {
    width: 400px;
  }
  .content {
    height: calc(100vh - 260px);
    border: 1px solid #d9d9d9;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    overflow: auto;
    span {
      display: block;
      height: 36px;
      line-height: 36px;
      background: #f7f7f7;
      padding-left: 16px;
    }
    ul {
      padding: 20px;
    }
    li {
      margin-bottom: 16px;
      p {
        margin-bottom: 10px;
      }
      div {
        width: 80%;
        color: #888;
      }
    }
    textarea {
      flex: 1;
      border: none;
      outline: none;
      resize: none;
      padding: 20px;
    }
  }
  .example {
    display: flex;
    flex-direction: column;
    height: calc(100vh - 260px);
    border: 1px solid #d9d9d9;
    border-radius: 8px;
    ul {
      padding: 20px;
      flex: 1;
      overflow: auto;
    }
    section {
      width: 360px;
      height: 176px;
      background: #f7f7f7;
      margin: 10px auto;
      border-radius: 8px;
      textarea {
        height: 130px !important;
        background: #f7f7f7;
        border: none;
        outline: none;
        resize: none;
        border-radius: 8px;
        box-shadow: none;
      }
      button {
        margin-left: 280px;
      }
    }
  }
}
.example {
  overflow: hidden;
  h2 {
    display: block;
    height: 36px;
    line-height: 36px;
    background: #f7f7f7;
    padding-left: 16px;
    font-size: 14px;
    font-weight: normal;
    border-radius: 8px 8px 0 0;
    border-bottom: 1px solid #d9d9d9;
  }
  ul {
    padding: 16px;
    height: 95%;
    overflow: auto;
    li {
      margin-bottom: 10px;
      cursor: pointer;
      div {
        margin: 0 auto;
        margin-top: 10px;
        border-radius: 8px;
        padding: 8px 12px;
        color: #888;
      }
    }
  }
}

/deep/
  .ant-radio-group-solid
  .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
  color: #444;
  background: #e6e8f0;
  border-color: #e6e8f0;
}

/deep/.ant-radio-button-wrapper-checked:not(
    .ant-radio-button-wrapper-disabled
  )::before {
  /* background-color: #0256ff; */
  background-color: #fff;
}

textarea:disabled {
  background-color: #fff;
}

@media (max-width: 1440px) {
  .right_action {
    .ant-input {
      width: 210px;
    }
    .ant-select {
      margin: 0 3px;
    }
  }
}
</style>
