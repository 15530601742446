import { MQTT } from "@/utils/mqtt.js";
import { onUnmounted, ref } from "vue";
export default function useMqtt() {
  const listenMqttIsntance = ref();
  const sendMqttIsntance = ref();

  const startMqtt = (topic, callback, rCallback) => {
    listenMqttIsntance.value = new MQTT(topic);
    listenMqttIsntance.value.init();
    listenMqttIsntance.value.subscribe(rCallback);
    listenMqttIsntance.value.listen(callback);
    return listenMqttIsntance.value;
  };

  const publishMqtt = (topic, payload, callback, rCallback) => {
    if (sendMqttIsntance.value) {
      sendMqttIsntance.value.unsubscribe();
      sendMqttIsntance.value.over();
    }
    sendMqttIsntance.value = new MQTT(topic);
    sendMqttIsntance.value.init();
    sendMqttIsntance.value.subscribe((err) => {
      rCallback(err);
      if (!err) {
        sendMqttIsntance.value.publish(topic, payload);
      } 
    });
    sendMqttIsntance.value.listen(callback);
    return sendMqttIsntance.value;
  };

  onUnmounted(() => {
    if (listenMqttIsntance.value) {
      listenMqttIsntance.value.unsubscribe();
      listenMqttIsntance.value.over();
    }
    if (sendMqttIsntance.value) {
      sendMqttIsntance.value.unsubscribe();
      sendMqttIsntance.value.over();
    }
  });

  return {
    startMqtt,
    publishMqtt,
  };
}
