<template>
  <a-modal :visible="openFlag" title="模拟运行" @cancel="handleCancel">
    <div>
      <section class="topic" v-if="type === 'up' ">
        <p>topic：</p>
        <a-textarea v-model:value="topic" rows="1"></a-textarea>
      </section>
      <section v-if="type === 'up'">
        <p>payload：</p>
        <a-textarea v-model:value="payload" rows="8"></a-textarea>
      </section>
      <section v-if="type === 'down'">
        <p>cmd：</p>
        <a-textarea v-model:value="cmd" rows="8"></a-textarea>
      </section>
    </div>
    <template #footer>
      <a-button key="back" @click="handleCancel">取消</a-button>
      <a-button key="submit" type="primary" :loading="loading" @click="handleOk"
        >运行</a-button
      >
    </template>
  </a-modal>
</template>
<script setup>
import { apiGateWay } from "@/api/gateway.js";
import { defineProps, defineEmits, ref } from "vue";
import { useRoute } from "vue-router";

const props = defineProps({
  openFlag: {
    type: Boolean,
    default: true,
  },
  type: String
});
const route = useRoute();

const emit = defineEmits(["close","success"]);
const handleCancel = () => {
  emit("close");
};

const topic = ref("");
const payload = ref("");
const cmd = ref("");
const handleOk = () => {
  let api = props.type === 'up'?apiGateWay.runGatewayJSUp:apiGateWay.runGatewayJSDown;
  let param = {}
  if(props.type === 'up'){
    param = {
      topic: topic.value,
      payload: payload.value,
    }
  }else{
    param = {
      cmd: cmd.value,
    }
  }
  api(route.params.bizId, param)
    .then((res) => {
      emit('success', res.result)
    });
};
</script>
<style lang="less">
p {
  margin-bottom: 4px;
}
.topic {
  margin-bottom: 20px;
}
</style>
