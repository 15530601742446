<template>
  <div>
    <div class="header">
      <a-radio-group
        v-model:value="scriptType"
        button-style="solid"
        @change="tabChange"
      >
        <a-radio-button value="up">上行数据处理脚本</a-radio-button>
        <a-radio-button value="down">下行数据处理脚本</a-radio-button>
      </a-radio-group>
      <section>
        <a-button type="primary" v-if="!editFlag" @click="handleEdit"
          >修改</a-button
        >
        <a-button v-if="editFlag" @click="handleCancel">取消</a-button>
        <a-button type="primary" v-if="editFlag" @click="handleSave"
          >保存</a-button
        >
        <a-button class="run" @click="handleRunScript">模拟运行</a-button>
      </section>
    </div>
    <div class="body">
      <div class="content">
        <span class="top">{{ funcHeader }}</span>
        <textarea
          name=""
          id=""
          cols="30"
          rows="10"
          :disabled="!editFlag"
          v-model="funcBody"
          ref="textareaRef"
        ></textarea>
        <span class="bottom">}</span>
      </div>
      <div class="example">
        <h2>返回对象格式 / 入参对象格式</h2>
        <ul>
          <li
            v-for="item in exampleData"
            :key="item.name"
            
          >
            <span @click="item.hiddenFlag = !item.hiddenFlag">
              <CaretRightOutlined
                v-if="!item.hiddenFlag"
                style="color: #0047ba"
              />
              <CaretDownOutlined v-else style="color: #0047ba" />
              {{ item.name }}
            </span>
            <div v-show="item.hiddenFlag">
              <pre v-html="JSON.stringify(item.payload, null, 3)"></pre>
            </div>
          </li>
        </ul>
      </div>
    </div>
    <imitate-run
      v-if="runModalVisibleFlag"
      :openFlag="runModalVisibleFlag"
      :type="scriptType"
      @close="closeModal"
      @success="handleSuccess"
    />
    <imitate-result
      v-if="runModalVisibleFlag"
      :openFlag="runModalResultVisibleFlag"
      :data="runResult"
      @close="closeModal"
      @editJs="handleEditJS"
      @restart="handleRestart"
    />
  </div>
</template>
<script setup>
import { ref, onMounted, nextTick, watch, defineProps } from "vue";
import { apiGateWay } from "@/api/gateway.js";
import { useRoute } from "vue-router";
import { message } from "ant-design-vue";
import imitateRun from "./imitateRun";
import imitateResult from "./imitateResult";

const textareaRef = ref(null);
const scriptType = ref("up");
const runModalVisibleFlag = ref(false);
const runModalResultVisibleFlag = ref(false);
const editFlag = ref(false);

const props = defineProps({
  data:{
    type: Object,
    default(){
      return {}
    }
  }
})
const route = useRoute();

watch(
  () => route.fullPath,
  () => {
    if (route.fullPath.includes("/IoTManage/gateway/detail")) {
      nextTick(() => {
        initUpScript();
        initExample();
      });
    }
  }
);

const handleRunScript = () => {
  if (editFlag.value) {
    message.warning("请先保存脚本");
    return;
  }
  runModalVisibleFlag.value = true;
};

const closeModal = () => {
  runModalVisibleFlag.value = false;
  runModalResultVisibleFlag.value = false;
};

const funcHeader = ref("");
const funcBody = ref("");
const initUpScript = () => {
  let api =
    scriptType.value === "up"
      ? apiGateWay.getGatewayJSUp
      : apiGateWay.getGatewayJSDown;
  api(route.params.bizId).then((res) => {
    let firstIndex = res.result.indexOf("{");
    let lastIndex = res.result.lastIndexOf("}");
    funcHeader.value = res.result.slice(0, firstIndex + 1);
    funcBody.value = res.result.slice(firstIndex + 1, lastIndex);
  });
};

const exampleData = ref(null);
const initExample = () => {
  let api =
    scriptType.value === "up"
      ? apiGateWay.getGatewayJSUpFormat
      : apiGateWay.getGatewayJSDownFormat;
  api(route.params.bizId).then((res) => {
    exampleData.value = res.result;
  });
};

const handleSave = () => {
  let func = funcHeader.value + funcBody.value + "}";
  let api =
    scriptType.value === "up"
      ? apiGateWay.saveGatewayJSUp
      : apiGateWay.saveGatewayJSDown;
  api(route.params.bizId, {func}).then(() => {
    editFlag.value = false;
    message.success("保存成功！");
    initUpScript();
  });
};

const handleCancel = () => {
  initUpScript();
  editFlag.value = false;
};

const runResult = ref(null);
const handleSuccess = (result) => {
  runModalResultVisibleFlag.value = true;
  runResult.value = result;
};

const tabChange = () => {
  initUpScript();
  initExample();
};

const handleEdit = () => {
  if(props.data.status == '01'){
    message.warning("网关启用状态，禁止修改！");
    return;
  }
  editFlag.value = true;
  nextTick(() => {
    textareaRef.value && textareaRef.value.focus();
    //  textareaRef.value && textareaRef.value.setSelectionRange(90, 90);
  });
};

const handleEditJS = () => {
  closeModal();
  handleEdit();
};
const handleRestart = () => {
  runModalResultVisibleFlag.value = false;
};

onMounted(() => {
  initUpScript();
  initExample();
});
</script>
<style lang="less" scoped>
.header {
  display: flex;
  section {
    margin-left: 80px;
    button {
      margin-right: 10px;
    }
    .run {
      background: #07b9b9;
      color: #fff;
    }
  }
}
.body {
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
  .content {
    width: 62%;
    height: calc(100vh - 260px);
    border: 1px solid #d9d9d9;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    span {
      display: block;
      height: 36px;
      line-height: 36px;
      background: #f7f7f7;
      padding-left: 16px;
    }
    .top {
      border-bottom: 1px solid #d9d9d9;
    }
    .bottom {
      border-top: 1px solid #d9d9d9;
    }
    textarea {
      flex: 1;
      border: none;
      outline: none;
      resize: none;
      padding: 20px;
    }
  }
  .example {
    width: 36%;
    height: calc(100vh - 260px);
    border: 1px solid #d9d9d9;
    border-radius: 8px;
  }
}
.example {
  overflow: hidden;
  h2 {
    display: block;
    height: 36px;
    line-height: 36px;
    background: #f7f7f7;
    padding-left: 16px;
    font-size: 14px;
    font-weight: normal;
    border-radius: 8px 8px 0 0;
    border-bottom: 1px solid #d9d9d9;
  }
  ul {
    padding: 16px;
    height: 95%;
    overflow: auto;
    li {
      margin-bottom: 10px;
      cursor: pointer;
      div {
        width: 93%;
        margin: 0 auto;
        margin-top: 10px;
        background: #f7f7f7;
        border-radius: 8px;
        padding: 8px 12px;
      }
    }
  }
}

/deep/
  .ant-radio-group-solid
  .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
  color: #444;
  background: #e6e8f0;
  border-color: #e6e8f0;
}

/deep/.ant-radio-button-wrapper-checked:not(
    .ant-radio-button-wrapper-disabled
  )::before {
  /* background-color: #0256ff; */
  background-color: #fff;
}

textarea:disabled {
  background-color: #fff;
}
</style>
